<script setup lang="ts">
  import { formatText, sanitize } from '~/utils/format-text'
  import type { PloneTileRichtext } from '~/types/Plone'

  const props = defineProps<{
    data: PloneTileRichtext
  }>()

  const text = props.data.text.trim().replace(/<[^\/>][^>]*><\/[^>]+>/g, '')

  const isTable = text.startsWith('<table') && text.endsWith('</table>')

  const isStandaloneTitle = (text.startsWith('<h1') && text.endsWith('</h1>'))
      || (text.startsWith('<h2') && text.endsWith('</h2>'))
      || (text.startsWith('<h3') && text.endsWith('</h3>'))
      || (text.startsWith('<h4') && text.endsWith('</h4>'))
</script>

<template>
  <app-container
    :class="['tile-richtext', {
      'tile-richtext--standalone-title': isStandaloneTitle,
    }]"
    :text="!isTable">
    <div :class="['tile-richtext__wrap', {
      'tile-richtext__wrap--table': isTable
      }]">
      <div
        v-if="data.text"
        class="tile-richtext__content"
        v-html="formatText(sanitize(data.text))">
      </div>
    </div>
  </app-container>
</template>

<style scoped>
  .tile-richtext {
    &:deep(h2) {
      &:not(:first-child) {
        margin-top: var(--gap-500);
      }
    }

    &:deep(h3, h4) {
      font-weight: bold;
      &:not(:first-child) {
        margin-top: var(--gap-450);
        margin-bottom: var(--gap-300);
      }
    }
  }

  .tile-richtext__wrap--table {
    overflow-x: auto;
  }

  .tile-richtext--standalone-title {
    margin-bottom: 0 !important;
  }
</style>
